

















import {ProduccionMesaResponse,ObtenerPalletId} from '@/entidades/KTB/Consulta/PalletProduccionResponse';
import { Planta } from '@/entidades/Maestro/Planta';
import {Vue,Component} from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
    name : 'ProduccionMesaPlanta',
    components:{
        'card' : () => import('@/components/KTB/Consulta/MesaCard.vue')
      
    }

})


export default class ProduccionMesa extends Vue
{

    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>
    listado:Array<ProduccionMesaResponse> =[];
    obtenerPallet(pallet:Array<ProduccionMesaResponse>)
    {
        this.listado = pallet;
    }
    async obtenerPalletDb()
    {
        try
        {
            var rsp = await ObtenerPalletId(this.plantas[0].plantaId);
            this.obtenerPallet(rsp.isResult);
        }
        catch(error)
        {
            this.listado = [];
        }
    }
    created() {
        //@ts-ignore
        this.$palletHub.$on('pallets',this.obtenerPallet);   
    }
    async mounted() {
        await this.obtenerPalletDb();
    }

}
